<template>
  <v-container class="mt-6 my-2 mx-2 px-2 col-md-12">
    <v-container class="authprev">
      <v-card class="pa-2">
        <!-- <v-btn @click="process">
          process
        </v-btn> -->
        <h1 class="maincolor1--text text-center">{{ $t("signin.name") }}</h1>
        <v-divider class="ma-3"></v-divider>
        <v-form ref="signin" v-model="validity">
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-text-field
              :label="$t('signin.form.username')"
             clearable
             outlined
             rounded
              name="username"
              autocomplete="false"
              v-model="username"
              :rules="usernameRules"
              
              prepend-inner-icon="mdi-account-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-text-field
              :label="$t('signin.form.password')"
              outlined
              rounded
              clearable
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              hint="At least 8 characters"
              counter
              prepend-inner-icon="mdi-key-outline"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <input type="hidden" v-model="csrf">
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 text-center">
            <v-btn
              class="col-md-12"
              rounded
              color="maincolor3"
              @click="handleSubmit"
              :dark="!loading"
              elevation="3"
              :loading="loading"
            >
              <v-icon>mdi-login-variant</v-icon> {{ $t("signin.name") }}
            </v-btn>
          </v-col>
        </v-form>
        <v-divider class="ma-3"></v-divider>
       
      </v-card>

      <v-card class="mt-3 pa-3 text-center maincolor2" shaped>
         <p class="white--text">{{ $t("signin.ifnot") }}</p>
        <router-link to="/register">
          <v-btn outlined color="white">
            <span >
            <v-icon>mdi-plus</v-icon> {{ $t("signin.create") }}
          </span>
          </v-btn>
        </router-link>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
// import io from 'socket.io-client'
export default {
  data() {
    return {
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      validity: false,
      loading: false,
      username: "",
      password: "",
      csrf: '',
      id: this.$route.params.id,
      // socket: io('localhost:3000')
    };
  },
  computed: {
    usernameRules() {
      return [
        (v) => !!v || this.$t("signin.errors.urequired"),
        (v) =>
          this.$pattern.username.test(v) || this.$t("signin.errors.invalidu"),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t("signin.errors.prequired"),
        (v) =>
          this.$pattern.password.test(v) || this.$t("signin.errors.invalidp"),
      ];
    },
    valid() {
      return this.$refs.signin.validate();
    },
  },
  methods: {
    successNotification(text) {
      this.$notify({
        group: "success",
        type: "success",
        title: "success",
        text: text,
      });
    },
    errorNotification(text, type = "error") {
      this.$notify({
        group: "errors",
        type: type,
        title: "error",
        text: text,
      });
    },

    handleSubmit() {
      if (this.valid) {
        this.loading = true;

        this.$store
          .dispatch("signIn", {
            
            username_u: this.username,
            password: this.password,
            "_csrf": "sGVtrGd6-zYpoG9QDbpreqC_Yz9uvYdL_A5Y",
            
          })
          .then(async (resul) => {
            this.loading = false;

            if (resul.status === 200 && resul.data.code === 200) {
              if (this.$route.query.nextPath) {
                this.$router
                  .push({
                    name: this.$route.query.nextPath,
                    params: this.$route.params,
                  })
                  .then(null, (err) => {
                    this.$router.push({ name: "NotFound" });
                    return err;
                  });
              } else this.$router.push({ name: "First" });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("signin.errors.auth"),
              this.$t("signin.errors.notRegistredYet"),
              "warning"
            );
            return err;
          });
      } else {
        this.$swal.fire(
          this.$t("signin.errors.auth"),
          this.$t("signin.errors.notRegistredYet"),
          "warning"
        );
        this.loading = false;
      }
    },
    process()
    {
      this.$http.post('http://localhost:3000/process',{
        "_csrf": this.csrf,
      })
      .then(res => {
        alert(res.data.csrfToken)
        // this.csrf = res.data.csrfToken
        // this.$http.defaults.headers.common['X-CSRF-TOKEN'] = this.csrf
      })
      .catch(error => alert(error))
    }
  },
  mounted(){
    this.$http.get('/form')
      .then(res => {
        this.csrf = res.data.csrfToken
        this.$http.defaults.headers.common['X-CSRF-TOKEN'] = this.csrf
      })
      // .catch(error => alert(error))
  }
};
</script>
